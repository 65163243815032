<template>
<v-container>
  <navbar title="ثبت نام"/>
    <v-main class="pt-15">
      <div>
        <error/>
      </div>
      <v-card class="mx-auto mt-9 card">
        <v-form @submit.prevent="register()" ref="form_register">
        <v-card-text>
          <!-- <v-text-field v-model="user_data.name" label="نام*" type="text" maxlength="21" :rules="[rules.required, rules.count]" required/> -->
          <!-- <v-text-field v-model="user_data.lastname" label="نام خانوادگی*" maxlength="21" type="text" :rules="[rules.required, rules.count]" required/> -->
          <v-text-field v-model="user_data.mobile" label="شماره همراه*" maxlength="11" type="tell" :rules="[ rules.required, rules.phone, rules.type_number ]" required/>
          <!-- <v-text-field v-model="user_data.national_code" label="کدملی*" maxlength="10" type="tell" :rules="[ rules.required, rules.type_number]" required/> -->
          <v-text-field 
          label="تعیین رمز عبور*" 
          v-model="user_data.password"
          :rules="[ rules.required, rules.count, rules.password ]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"/>
          <!-- <v-text-field :rules="[ rules.type_number ]" v-model="user_data.invite" label="کد معرف"/> -->
          <!-- <v-text-field :rules="[ rules.type_number ]" v-model="user_data.invite" label="کد معرف"/> -->
          <v-row>
            <v-col class="d-flex">
              <v-checkbox hide-details v-model="checkbox"></v-checkbox>
              <span class="medium-font">
                <span @click="change_page('rules')" class="medium-font font-weight-bold info--text text-decoration-underline">قوانین</span>
                را مطالعه کردم
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn @click="check()" color="primary" :class="{'is-loading': loading}" :disabled="loading || !accept" class="button w-100" type="submit">
            <loading v-if="loading" class="loading"/>ثبت نام
          </v-btn>
          <v-row class="mt-3">
            <v-col>
              <span @click="login()" class="info--text font d-block text-center">قبلا ثبت نام کرده ام</span>
            </v-col>
          </v-row>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-main>
  </v-container>
</template>
<style lang="scss" scoped>

.is-loading {
  color:transparent;
}
.loading{
  font-size: 8px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
.v-input--selection-controls{
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
<script>
import navbar from '@/components/navbar/appBar'
import { alertMessage } from '@/assets/messageAlert'
import { register } from './models/auth'
import { toEnglishDigits } from '@/models/changeType'
export default {
  components: {
    loading: () => import ('@/components/tools/loading'),
    error: () => import('@/components/alerts/error.alert'),
    navbar
  },
  data: () => ({
    user_data: {
      name: '',
      lastname: '',
      mobile: '',
      password: '',
      national_code: '',
      invite: '',
    },
    register_field: {},
    rules: {
      required: null,
      count: v => v.length <= 21 || alertMessage.error['MaxCharacters'],
      phone: v => {
        const mobile = toEnglishDigits(v)
        return mobile.slice(0,2) == '09' || alertMessage.error['typePhone']},
      type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage.error['type_number']
      },
      password: v => v.length >= 6 || alertMessage.error['min_password']
    },
    showPassword: false,
    checkbox: false
  }),
  computed: {
    loading: state => state.loadingSubmit,
    accept () {
      if (this.checkbox) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    const config = localStorage.getItem('config')
    if (config) {
      const _config = JSON.parse(config)
      this.register_field = JSON.parse(_config.other_configs).reg_fields
    }
  },
  methods: {
    check () {
      this.rules = {
        required: value => !!value || alertMessage.error['Required']
      }
    },
    register () {
      const validation = this.$refs.form_register.validate()
      if (validation) {
        register({
        first_name: this.user_data.name,
        last_name: this.user_data.lastname,
        mobile: toEnglishDigits(this.user_data.mobile),
        password: toEnglishDigits(this.user_data.password),
        invite_code: toEnglishDigits(this.user_data.invite),
        national_code: toEnglishDigits(this.user_data.national_code)
      })
      }
    },
    change_page(router) {
      this.$router.push(`/${router}`)
    },
    login() {
      this.$router.push('/login')
    }
  }
}
</script>